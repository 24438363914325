import React, { Component } from 'react'
import { Responsive, Container, Grid, GridRow, GridColumn, Header, Image } from 'semantic-ui-react'
import './Purpose.css'

export default class Purpose extends Component {
  render () {
    return (
      <div className="Purpose-wrapper">
        <Container>
          <Grid fluid stackable columns={2}>
            <GridRow stretched>
              <GridColumn>
              <div className="AboutCard-image">
                <Responsive minWidth={768}>
                  <Image src="https://res.cloudinary.com/dkbuneg9h/image/upload/v1720554006/Group_174_obuugj.svg" alt="opei2023" size="medium" rounded/>
                </Responsive>
              </div>
              </GridColumn>
              <GridColumn>
                <div className="Purpose-content-wrapper">
                  <div className="Purpose-title">
                    <Header as='h2'>Objetivo</Header>
                  </div>
                  <div className="Purpose-self-text" >
                    <p style={{ fontSize: '16px', marginTop: '10px' }}>
                                            O principal objetivo da OPEI é despertar o interesse pela matemática e computação, imprescindível
        na formação básica dos estudantes. Ela também permite que os competidores possam conhecer de forma mais abrangente a carreira e ramos na área,
        procurando engajar os participantes em ações do próprio PET-Informática após a competição. Por fim, a Olimpíada almeja preparar melhor e despertar o interesse de alunos
        pernambucanos para as competições nacionais e internacionais de programação, como a OBI e a ACM/ICPC.
                    </p>
                  </div>
                  <br/>
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
        </Container>
      </div>
    )
  }
}
