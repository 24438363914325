import './Navbar.css'
import React, { Component } from 'react'
import {
  Container,
  Icon,
  Image,
  Menu,
  Sidebar,
  Responsive,
  Segment,
  Grid,
  Dropdown,
} from 'semantic-ui-react'

import { HashLink as Link } from 'react-router-hash-link'

const NavBarMobile = ({
  children,
  onPusherClick,
  onToggle,
  onHide,
  visible,
  activeItem,
  onClick
}) => (
  <div>
    {!visible && <Menu fixed="top">
      <Menu.Item onClick={onToggle}>
        <Icon name="sidebar" />
      </Menu.Item>
      <Menu.Item as={Link} to="/" name="inscricao" active={activeItem === 'inscricao'} onClick={onClick}>
          <Image size="tiny" src="https://res.cloudinary.com/dkbuneg9h/image/upload/v1665717923/opei-2023/md8dkfuodi2gzoxbdu1t" />
      </Menu.Item>
    </Menu> }
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      vertical
      visible={visible}
      onHide={onHide}
      class="Menu-items"
    >
      <Menu.Item as={Link} name="sobre" content="Sobre" to="/sobre" active={activeItem === 'sobre'} onClick={onClick}/>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://opei.cin.ufpe.br/2024">Regulamento</a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/16oLX1yHEv0valif4JzhnHw0BHC-eQQa7?usp=sharing">Prova Teórica</a>
      </Menu.Item>
      <Menu.Item><a target="_blank" rel="noopener noreferrer" href="https://petinformatica.notion.site/Provas-Pr-tica-P-blico-ccb325405bde48b3a7c4f6cb07f6f725">Prova Prática</a></Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://opei.cin.ufpe.br/teorica/">Prova Teórica 2021</a>
      </Menu.Item>
      <Menu.Item as={Link} name="faq" content="Perguntas Frequentes" to="/sobre#perguntas-frequentes" active={activeItem === 'faq'}/>
    </Sidebar>
    <Sidebar.Pushable as={Segment} style={{ marginTop: 0 }}>
      <Sidebar.Pusher
        dimmed={visible}
        onClick={onPusherClick}
        style={{ minHeight: '5vh' }}
      >
        {visible && <Menu fixed="top">
          <Menu.Item onClick={onToggle}>
            <Icon name="sidebar" />
          </Menu.Item>
          <Menu.Item as={Link} to="/#inscricoes" name="inscricao" active={activeItem === 'inscricao'} onClick={onClick}>
              <Image size="tiny" src="https://res.cloudinary.com/dkbuneg9h/image/upload/v1665717923/opei-2023/md8dkfuodi2gzoxbdu1t" />
          </Menu.Item>
        </Menu> }
        <Segment basic>
          {children}
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  </div>
)

const NavBarDesktop = (
  activeItem,
  onClick
) => (

  <div className="Navbar">
    <Menu text >
      <Menu.Menu position="left">
        <Menu.Item as={Link} to="/">
            <Image size="small" src="https://res.cloudinary.com/dkbuneg9h/image/upload/v1665717923/opei-2023/md8dkfuodi2gzoxbdu1t" />
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right">
        <Menu text size="massive">
            <Grid columns={3} id="Menu-items" class="Menu-items">
            <Grid.Row>
              <Grid.Column>
                <Dropdown name='informacoes' active={activeItem === 'informacoes'} text='Informações' pointing="top" className='link item'>
                  <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/sobre" className='link item'>Sobre</Dropdown.Item>
                    {/* <Dropdown.Item as={Link} to="/material-divulgacao">Material de divulgação</Dropdown.Item> */}
                    <Dropdown.Item><a target="_blank" rel="noopener noreferrer" href="https://opei.cin.ufpe.br/2024">Regulamento</a></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>

              <Grid.Column>
                <Dropdown name='pratique' active={activeItem === 'pratique'} text='Pratique' pointing='top' className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Item><a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/16oLX1yHEv0valif4JzhnHw0BHC-eQQa7?usp=sharing">Prova Teórica</a></Dropdown.Item>
                    <Dropdown.Item><a target="_blank" rel="noopener noreferrer" href="https://petinformatica.notion.site/Provas-Pr-tica-P-blico-ccb325405bde48b3a7c4f6cb07f6f725">Prova Prática</a></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column>
                <Menu.Item as={Link} name='faq' active={activeItem === 'faq'} content='Perguntas Frequentes' to='/sobre#perguntas-frequentes'/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Menu>
      </Menu.Menu>
    </Menu>
  </div>
)

const NavBarChildren = ({ clas, children }) => (
  <Container fluid className={'inv-lat-marg ' + clas}>{children}</Container>
)


export default class Navbar extends Component {
  state = {
    activeItem: 'inscricao',
    visible: false
  };


  handleItemClick = (e, { name }) => this.setState({ activeItem: name, visible: !this.state.visible})

  handlePusher = () => {
    const { visible } = this.state

    if (visible) this.setState({ visible: false })
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  handleSidebarHide = () => this.setState({ visible: false })

  render () {
    const { children } = this.props
    const { visible } = this.state

    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <NavBarMobile
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            onClick={this.handleItemClick}
            onHide={this.handleSidebarHide}
            visible={visible}
          >
            <NavBarChildren clas='mobile'>{children}</NavBarChildren>
          </NavBarMobile>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <NavBarDesktop
            onClick={this.handleItemClick}/>
          <NavBarChildren clas='desktop'>{children}</NavBarChildren>
        </Responsive>
      </div>
    )
  }
}
