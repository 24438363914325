import React, { Component } from 'react'
import Calendar from './Calendar/Calendar'
import { Responsive, Segment, Image, Button } from 'semantic-ui-react'
import './Landing.css'

export default class Landing extends Component {
  render() {
    return (
      <div className="Landing-wrapper ">
        <Responsive as={Segment} basic >
          <div className="Landing-content-wrapper">
            <div className="Landing-text">
              <Segment basic className="Landing-segment">
                <div className="Landing-self-text">
                  <div>

                  <p>
                      <div className="Landing-title">Vem aí: OPEI 2024!</div>
                      A Olimpíada Pernambucana de Informática, OPEI, é uma competição para os estudantes de Ensinos Fundamental,
                      Médio e Superior das instituições públicas e privadas de todo o estado de Pernambuco.
                      <br />
                      Nossa X edição será realizada totalmente presencial!
                    </p>
                
                    <a target="_blank" rel="noopener noreferrer" href="https://opei.cin.ufpe.br/2024/"><Button positive size={'big'}>INSCREVA-SE JÁ</Button></a>
                    <Calendar />

                  </div>
                </div>
              </Segment>
            </div>
          </div>
        </Responsive>

        <Responsive basic minWidth={992}>
          <div className="Landing-image">
            <Image src="https://res.cloudinary.com/dkbuneg9h/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1720551151/Group_193_novsof.svg" size="big" />
          </div>
        </Responsive>
      </div>

    )
  }
}
