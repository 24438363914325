import React, { Component } from 'react'
import Landing from './Landing/Landing'
import Sponsors from './Sponsors/Sponsors'
// import News from './News/News'
import './Home.css'

export default class Home extends Component {
  render () {
    return (
      <div className="Home">
        <Landing/>
        {/* <About/> */}
        {/* <News/> */}
        <Sponsors/>
      </div>
    )
  }
}
