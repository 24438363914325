import React, { Component } from 'react'
import realizadores from './pet-cin-ufpe.svg'

// const pet = require('./pet.svg');
// const cinufpe = require('./cinufpe.svg');
// const thoughtworks = require('./thoughtworks.svg');
// const even3 = require('./even3.svg');
// const inloco = require('./inloco.svg');

const style = {
  'outerflex': {
    'display': 'block',
    'flexDirection': 'column',
    'textAlign': 'center',
    'margin': 'auto',
    'padding': 16,
  },
  'innerflex': {
    'display': 'flex',
    'justifyContent': 'space-evenly',
    'flexDirection': 'row',
    'alignItems': 'center',
    'maxWidth': '1024px',
    'margin': 'auto',
  },
  'img': {
    'width': '100%',
    'padding': 16,
  },
};

export default class Sponsors extends Component {
  render () {
    return (
      <div style={style.outerflex}>
        <h2 class="ui header">Realização</h2>
        <div style={style.innerflex}>
        <img
          alt="Realizadores"
          src={realizadores}
          style={{
            ...style.img,
          }}
        />
        </div>
        
        <h2 class="ui header">Patrocínio</h2>
        <div style={style.innerflex}>
        <img
          alt="Logo da empresa incognia"
          src="https://res.cloudinary.com/dkbuneg9h/image/upload/v1665717310/opei-2023/cp9uyjkd8r0wa82uf2d6"
          style={{
            ...style.img,
            width: '40%'
          }}
        />
        <img
          alt='Logo da empresa comeia'
          src='https://i.ibb.co/F0LZkY0/logocomeia.png'
          style={{
            ...style.img,
            width: '30%'
          }}
        />
        </div>
      </div>
    )
  }
}
