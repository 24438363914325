import React, { Component } from 'react'
import Accordion from './../../../components/Accordion/AccordionCustom'
import { HashLink as Link } from 'react-router-hash-link'
import { Grid, GridRow, Header } from 'semantic-ui-react'

import './FAQ.css'

export default class FAQ extends Component {
  render () {
    const items = [
      {
        title: <p>Como a prova será realizada?</p>,
        content: <p>A X edição da OPEI será de forma totalmente presencial, com as provas acontecendo no dia 28 de setembro no Centro de Informática da Universidade Federal de Pernambuco, campus Recife, e na Universidade de Pernambuco, campus Caruaru.
          <br/>
        É importante acompanhar o nosso <a href='https://www.instagram.com/opeinformatica' alt='instagram'>Instagram</a> para mais orientações.
        </p>
      },
      {
        title: <p>Como será a Premiação deste ano?</p>,
        content: <p>Como costume, a OPEI conta com certificados e medalhas para estudantes premiados, conforme descrito em nosso Regulamento.
        Após a divulgação dos resultados iremos entrar em contato para acordar o envio para delegados das instituições de ensino, e também divulgaremos mais detalhes sobre a cerimônia.
        </p>
      }
      // {
      //   title: <p>Pergunta</p>,
      //   content: <p>Resposta</p>
      // }
    ]
    return (
      <div className="FAQ-wrapper">
        <Grid padded>
          <GridRow>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={14}>
              <div className="FAQ-Title">
                <Header as='h2'>Perguntas Frequentes
                  <Header.Subheader>Tem alguma dúvida? Veja se encontra a resposta aqui! Caso contrário, entre em <Link to="/sobre#contato">contato</Link> conosco! </Header.Subheader>
                </Header>
              </div>
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
          </GridRow>
        </Grid>
        <Grid padded>
          <GridRow id="accordion-item">
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={14}>
              <Accordion items={items}/>

            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
          </GridRow>
        </Grid>
      </div>
    )
  }
}
